import React, { useMemo } from "react"
import { Badge, ConfigProvider, Menu, MenuProps, Tooltip } from "antd"
import Icon, {
  CalendarOutlined,
  BookOutlined,
  SafetyOutlined,
  HomeOutlined,
  ClusterOutlined,
  TeamOutlined,
  DesktopOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  SlackOutlined,
  ReadOutlined,
  RobotOutlined,
  LoginOutlined,
  BarChartOutlined
} from "@ant-design/icons"
import cn from "classnames"
import { useAppDispatch, useAppSelector, useAuthActions, useRoleAbilities } from "@hooks"
import { mainMenuRoutes } from "@routes/mainMenuRoutes"
import { RouteItem } from "@routes/types"
import { appActions, selectOpenMenuKeys } from "@state/reducers/App"
import { Link, useLocation } from "react-router-dom"
import { VACATIONS_ROUTE } from "@routes/inboundRoutes"
import { authenticationApi } from "@state/services/subApis"
import { PersonalSettingsIcon } from "@assets/svg/personalSettings"

import { Logo } from "@components/Logo"

import style from "./style.m.scss"

const ROOT_PATH = "/"

const IconMapping: Record<string, React.ReactNode> = {
  home: <HomeOutlined />,
  users: <TeamOutlined />,
  projects: <DesktopOutlined />,
  internship: <BookOutlined />,
  vacations: <CalendarOutlined />,
  orgChart: <ClusterOutlined />,
  permissions: <SafetyOutlined />,
  departments: <TeamOutlined />,
  myProfile: <UserOutlined />,
  myTeam: <TeamOutlined />,
  personalSettings: <Icon component={PersonalSettingsIcon} />,
  settings: <SettingOutlined />,
  slack: <SlackOutlined />,
  educationalMaterials: <ReadOutlined />,
  bannedIps: <RobotOutlined />,
  supportPortal: <LoginOutlined />,
  hrDashboard: <BarChartOutlined />
}

type MenuItem = Required<MenuProps>["items"][number]

const generateRouteLabel = (route: RouteItem, disabled?: boolean) => {
  const visibleRoutes = route.subRoutes ? route.subRoutes.filter(subRoute => !subRoute.hidden) : []
  const moddedTitle = disabled ? (
    <Tooltip title="Work in progress" placement="right">
      {route.title}
    </Tooltip>
  ) : (
    route.title
  )
  if (visibleRoutes.length === 1) return <Link to={visibleRoutes[0].path}>{moddedTitle}</Link>

  return route.component ? <Link to={route.path}>{moddedTitle}</Link> : moddedTitle
}

const isSubroutesShouldBeVisible = (subRoutes?: Array<RouteItem>) => {
  if (!subRoutes) return false
  const visibleRoutes = subRoutes ? subRoutes.filter(subRoute => !subRoute.hidden) : []

  return visibleRoutes.length > 1
}

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const openMenuKeys = useAppSelector(selectOpenMenuKeys)

  const { newVacationRequestsExists, isSuperaccess } = authenticationApi.endpoints.fetchMe.useQuery(undefined, {
    selectFromResult: ({ data }) => ({
      newVacationRequestsExists: data?.data.attributes.pending_leave_requests,
      isSuperaccess: data?.data.attributes.is_superaccess || false
    })
  })

  const handleMenuOpenChange: MenuProps["onOpenChange"] = keys => {
    dispatch(appActions.setOpenMenuKeys(keys))
  }

  const { handleSignOut } = useAuthActions()

  const abilities = useRoleAbilities()
  const isChief = useAppSelector(state => state.meReducer.user?.attributes.is_chief) || false
  const withSubordinates = useAppSelector(state => state.meReducer.user?.attributes.with_subordinates) || false

  const topRoutes = useMemo(
    () => mainMenuRoutes(abilities, isChief, isSuperaccess, withSubordinates),
    [abilities, isChief, isSuperaccess, withSubordinates]
  )

  const selectableRoutes = useMemo(() => {
    const notRootRoutes = topRoutes.filter(route => route.path !== ROOT_PATH)
    const subRoutes = topRoutes
      .filter(route => route.subRoutes && route.subRoutes.length > 0)
      .reduce((acc, value) => [...acc, ...(value.subRoutes as Array<RouteItem>)], [])
    return [...notRootRoutes, ...subRoutes]
  }, [topRoutes])

  const deriveMenuItems = (routes: Array<RouteItem>, level: number = 0): Array<MenuItem> =>
    routes
      .filter(route => !route.hidden)
      .map(route => ({
        key: route.path,
        label: generateRouteLabel(route, route.disabled),
        icon:
          route.path === VACATIONS_ROUTE ? (
            <Badge dot={newVacationRequestsExists}>{IconMapping[route.slug]}</Badge>
          ) : (
            IconMapping[route.slug]
          ),
        children: isSubroutesShouldBeVisible(route.subRoutes)
          ? deriveMenuItems(route.subRoutes!, level + 1)
          : undefined,
        className: cn(style.menuItem, level === 0 ? style.topLevelItem : style.nestedItem),
        disabled: route.disabled
      }))

  const menuItems = useMemo(() => deriveMenuItems(topRoutes), [topRoutes, newVacationRequestsExists])

  const selectedKeys =
    pathname === ROOT_PATH
      ? [ROOT_PATH]
      : selectableRoutes.filter(route => pathname.includes(route.path)).map(route => route.path)

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBorderRadius: 0
          }
        }
      }}
    >
      <div>
        <Logo />
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openMenuKeys}
          onOpenChange={handleMenuOpenChange}
          selectedKeys={selectedKeys}
          items={menuItems}
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={[
          {
            key: "logout",
            label: "Log out",
            onClick: handleSignOut,
            icon: <LogoutOutlined />
          }
        ]}
      />
    </ConfigProvider>
  )
}
