import { Build } from "@state/services/types"
import { AttritionRateStatistics, HrDashboardDataQuery } from "@state/types/hrDashboard"

import { generateFetchAttritionRateDataURL } from "../urls"

export const fetchAttritionRateData = (build: Build) =>
  build.query<AttritionRateStatistics, HrDashboardDataQuery>({
    query: params => ({
      url: generateFetchAttritionRateDataURL(params)
    }),
    providesTags: ["HrDashboard"]
  })
