import { ChartOptions } from "chart.js"

export const deriveGraphConfigOptions = (title: string, titleX: string, titleY: string): ChartOptions<"line"> => ({
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: title
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: titleX
      }
    },
    y: {
      ticks: {
        stepSize: 1
      },
      title: {
        display: true,
        text: titleY
      }
    }
  }
})
