import { HrDashboardDataQuery } from "@state/types/hrDashboard"

export const HR_DASHBOARD_URL = "/hr_dashboard"
export const HR_DASHBOARD_DATA_URL = `${HR_DASHBOARD_URL}/summary_statistics`
export const ATTRITION_RATE_DATA_URL = `${HR_DASHBOARD_URL}/attrition_rate_statistics`

const separator = "?"

export const generateFetchHrDashboardDataURL = ({ startDate, endDate, product }: HrDashboardDataQuery) => {
  const searchParams = new URLSearchParams()

  if (startDate) searchParams.set("from", String(startDate))
  if (endDate) searchParams.set("to", String(endDate))
  searchParams.set("product", product)

  return `${HR_DASHBOARD_DATA_URL}${separator}${searchParams.toString()}`
}

export const generateFetchAttritionRateDataURL = ({ startDate, endDate, product }: HrDashboardDataQuery) => {
  const searchParams = new URLSearchParams()

  if (startDate) searchParams.set("from", String(startDate))
  if (endDate) searchParams.set("to", String(endDate))
  searchParams.set("product", product)

  return `${ATTRITION_RATE_DATA_URL}${separator}${searchParams.toString()}`
}
