import { Build } from "@state/services/types"

import { generateEducationalMaterialURL } from "../urls"

export const fetchEducationalMaterial = (build: Build) =>
  build.query<Blob, string>({
    query: id => ({
      url: generateEducationalMaterialURL(id),
      responseHandler: async response => {
        const blob = await response.blob()
        return blob
      }
    }),
    providesTags: ["EducationalMaterial"]
  })
