import React, { FC } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { hrDashboardApi } from "@state/services/subApis"
import { useAppSelector } from "@hooks"
import { Line } from "react-chartjs-2"
import { BoardView } from "@enums"

import { deriveGraphLabels } from "../../helpers/datesUtils"
import { GRAPH_COLORS } from "../../helpers/colors"
import { deriveGraphConfigOptions } from "../../helpers/grapghConfig"

import { AttritionRateHeader } from "./AttritionRateHeader"
import { AttritionRateTable } from "./AttritionRateTable"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const AttritionRateBoard: FC = () => {
  const { startDate, endDate, boardView } = useAppSelector(state => state.hrDashboardSlice.attritionRateBoard)
  const product = useAppSelector(state => state.hrDashboardSlice.filters.product)

  const { data: attritionRate } = hrDashboardApi.endpoints.fetchAttritionRateData.useQuery({
    startDate,
    endDate,
    product
  })

  const deriveDataForGraph = () => {
    const labels = deriveGraphLabels(startDate, endDate)
    const values = attritionRate ? attritionRate?.graph.map(item => item[1]) : []

    return {
      labels,
      datasets: [
        {
          label: "Attrition rate",
          data: values,
          fill: false,
          borderColor: GRAPH_COLORS.ORANGE,
          backgroundColor: GRAPH_COLORS.TRANSPARENT_ORANGE
        }
      ]
    }
  }

  const options = deriveGraphConfigOptions("Line Chart Showing Employee Attrition Rate", "", "Attrition Rate")

  return (
    <>
      <AttritionRateHeader />
      {boardView === BoardView.Graph && <Line data={deriveDataForGraph()} options={options} />}
      {boardView === BoardView.Table && attritionRate && <AttritionRateTable tableData={attritionRate?.table} />}
    </>
  )
}
