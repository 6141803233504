import { Build } from "@state/services/types"
import { HrDashboardDataQuery, HrDashboardResponse } from "@state/types/hrDashboard"
import { HrDashboardData } from "@interfaces*"

import { generateFetchHrDashboardDataURL } from "../urls"

export const fetchDashboardData = (build: Build) =>
  build.query<HrDashboardData, HrDashboardDataQuery>({
    query: params => ({
      url: generateFetchHrDashboardDataURL(params)
    }),
    transformResponse: (res: HrDashboardResponse) => ({
      attritionRate: res.attrition_rate,
      trainingStatistics: res.training_statistics,
      employeesMetrics: res.employees_metrics
    }),
    providesTags: ["HrDashboard"]
  })
