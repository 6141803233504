import React, { FC } from "react"
import { UploadOutlined } from "@ant-design/icons"
import { requiredRule } from "@app/sections/Profile/helpers/validation"
import { defaultError } from "@helpers"
import { useAlert } from "@hooks"
import { Button, Col, Form, Row, Select, UploadFile } from "antd"
import Upload, { UploadChangeParam } from "antd/es/upload"
import dayjs from "dayjs"
import { performanceReviewApi } from "@state/services/subApis"

type FileFormProps = {
  userId: string
}

type FormFile = UploadFile<unknown>

type FormFields = {
  file: Array<FormFile>
  year: string
}

const possibleYearsDifference = 5

const currentYear = dayjs().format("YYYY")

const makeOptions = () => {
  const yearArray = []
  for (let i = 0; i <= possibleYearsDifference; i += 1) {
    yearArray.push(Number(currentYear) - i)
  }

  return yearArray.map(year => ({ label: String(year), value: year }))
}

const initialValues = {
  year: currentYear
}

const FileForm: FC<FileFormProps> = ({ userId }) => {
  const [addPerformanceReview, { isLoading }] = performanceReviewApi.endpoints.createPerformanceReview.useMutation()

  const { success, error } = useAlert()

  const [form] = Form.useForm<FormFields>()
  const file = Form.useWatch("file", form)

  const getFile = (e: UploadChangeParam<FormFile>) => {
    if (Array.isArray(e)) {
      return e
    }
    return e ? e.fileList : []
  }

  const onFinish = async ({ file, year }: FormFields) => {
    const formData = new FormData()
    formData.append("data[document_attributes][file]", file[0].originFileObj!)
    formData.append("data[user_id]", userId)
    formData.append("data[year]", year)

    try {
      await addPerformanceReview(formData).unwrap()
      form.resetFields()
      success("Performance review was successfully added")
    } catch {
      error(defaultError)
    }
  }

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={8}>
        <Col span={2.5}>
          <Form.Item name="file" valuePropName="fileList" rules={[requiredRule]} getValueFromEvent={getFile}>
            <Upload
              name="file"
              maxCount={1}
              accept=".pdf"
              beforeUpload={() => {
                return false
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="year">
            <Select style={{ width: "100px", marginRight: "8px" }} options={makeOptions()} />
          </Form.Item>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={!file?.length}>
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default FileForm
