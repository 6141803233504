import React, { FC } from "react"
import cn from "classnames"
import { Tooltip } from "antd"

import style from "./style.m.scss"

type CardProps = {
  tooltipText: React.ReactNode
  className?: string
  onClick?: () => void
}

export const Card: FC<CardProps> = ({ className, children, onClick, tooltipText }) => {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <Tooltip title={tooltipText} placement="left">
      <div className={cn(style.card, className)} onClick={handleClick}>
        {children}
      </div>
    </Tooltip>
  )
}
