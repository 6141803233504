import { Stack } from "@app/sections/Dashboard/components/Stack"
import React, { FC } from "react"
import { EmployeeMetrics } from "@interfaces*"

import { AttritionRateCard } from "../cards"
import { TrainingUndertakenCard } from "../cards/TrainingUndertakenCard"
import { EmployeeMetricsCard } from "../cards/EmployeeMetricsCard"

import { SectionWrapper } from "@components/SectionWrapper"

type HrDashboardCardsProps = {
  attritionRateData: number
  employeeMetrics: EmployeeMetrics
}

export const HrDashboardCards: FC<HrDashboardCardsProps> = ({ attritionRateData, employeeMetrics }) => (
  <SectionWrapper>
    <Stack>
      <AttritionRateCard attritionRate={attritionRateData} />
      <TrainingUndertakenCard />
      <EmployeeMetricsCard employeeMetrics={employeeMetrics} />
    </Stack>
  </SectionWrapper>
)
