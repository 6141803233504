import { BoardView } from "@enums"
import { useAppDispatch, useAppSelector } from "@hooks"
import { hrDashboardActions } from "@state/reducers/hrDashboard"
import { Button, DatePicker, Flex } from "antd"
import dayjs, { Dayjs } from "dayjs"
import React, { FC } from "react"

const disabledFutureMonths = (current: Dayjs) => current && current > dayjs().endOf("month")

export const AttritionRateHeader: FC = () => {
  const dispatch = useAppDispatch()

  const { startDate, endDate } = useAppSelector(state => state.hrDashboardSlice.attritionRateBoard)

  const handleGraphClick = () => {
    dispatch(hrDashboardActions.setAttritionRateBoardView(BoardView.Graph))
  }

  const handleTableClick = () => {
    dispatch(hrDashboardActions.setAttritionRateBoardView(BoardView.Table))
  }

  const handleLastYearClick = () => {
    const startOfLastYear = dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
    const endOfLastYear = dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD")

    dispatch(hrDashboardActions.setAttritionRatePeriod({ startDate: startOfLastYear, endDate: endOfLastYear }))
  }

  const handleThisYearClick = () => {
    const today = dayjs().format("YYYY-MM-DD")
    const startOfThisYear = dayjs().startOf("year").format("YYYY-MM-DD")

    dispatch(hrDashboardActions.setAttritionRatePeriod({ startDate: startOfThisYear, endDate: today }))
  }

  const handleMonthPickerChange = (monthRange: [Dayjs, Dayjs]) => {
    const startingMonth = monthRange[0].startOf("month").format("YYYY-MM-DD")
    const endingMonth = monthRange[1].endOf("month").format("YYYY-MM-DD")

    dispatch(hrDashboardActions.setAttritionRatePeriod({ startDate: startingMonth, endDate: endingMonth }))
  }

  return (
    <Flex justify="space-between">
      <Flex>
        <Button type="link" onClick={handleGraphClick}>
          Graph
        </Button>
        <Button type="link" onClick={handleTableClick}>
          Table
        </Button>
      </Flex>
      <Flex gap="small">
        <Button type="link" onClick={handleLastYearClick}>
          Last year
        </Button>
        <Button type="link" onClick={handleThisYearClick}>
          This year
        </Button>
        <DatePicker.RangePicker
          style={{ width: "220px" }}
          picker="month"
          value={[dayjs(startDate), dayjs(endDate)]}
          defaultValue={[dayjs(startDate), dayjs(startDate)]}
          disabledDate={disabledFutureMonths}
          format="MMM, YY"
          allowClear
          onChange={handleMonthPickerChange}
        />
      </Flex>
    </Flex>
  )
}
