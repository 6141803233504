import React, { FC } from "react"
import { productOptions } from "@helpers"
import { useAppDispatch, useAppSelector } from "@hooks"
import { hrDashboardActions } from "@state/reducers/hrDashboard"
import { Button, DatePicker, Flex, Select } from "antd"
import dayjs, { Dayjs } from "dayjs"

const deriveDisabledDate = (currentDate: Dayjs) => currentDate.isAfter(dayjs(), "day")

const allOption = { label: "All", value: "all" }

const deriveDatePickerValue = ([startDate, endDate]: [string | null, string | null]): [Dayjs | null, Dayjs | null] => {
  if (!startDate && !endDate) return [null, null]
  return [dayjs(startDate), dayjs(endDate)]
}

export const HrDashboardFilter: FC = () => {
  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(state => state.hrDashboardSlice)

  const handleThisYear = () => {
    dispatch(hrDashboardActions.setThisYear())
    dispatch(hrDashboardActions.setIsDatesChanges(true))
  }

  const handleLastMonth = () => {
    dispatch(hrDashboardActions.setLastMonth())
    dispatch(hrDashboardActions.setIsDatesChanges(true))
  }

  const handleThisMonth = () => {
    dispatch(hrDashboardActions.setThisMonth())
    dispatch(hrDashboardActions.setIsDatesChanges(true))
  }

  const handleDateRangeChange = ([startDate, endDate]: [Dayjs, Dayjs]) => {
    const formatedStartDate = dayjs(startDate).format("YYYY.MM.DD")
    const formatedEndDate = dayjs(endDate).format("YYYY.MM.DD")
    dispatch(hrDashboardActions.changeDateRange({ startDate: formatedStartDate, endDate: formatedEndDate }))
    dispatch(hrDashboardActions.setIsDatesChanges(true))
  }

  const handleProductChange = (product: string) => {
    dispatch(hrDashboardActions.setProduct({ product }))
  }

  const selectProductOptions = [allOption, ...productOptions]

  return (
    <Flex justify="flex-end" align="center" gap="small" style={{ margin: "20px 0" }}>
      <Select
        value={filters.product}
        options={selectProductOptions}
        onChange={handleProductChange}
        style={{ width: "150px" }}
      />
      <Button type="link" onClick={handleThisYear}>
        This year
      </Button>
      <Button type="link" onClick={handleLastMonth}>
        Last month
      </Button>
      <Button type="link" onClick={handleThisMonth}>
        This month
      </Button>
      <DatePicker.RangePicker
        style={{ width: "220px" }}
        allowClear={false}
        value={deriveDatePickerValue([filters.startDate, filters.endDate])}
        onChange={handleDateRangeChange}
        disabledDate={currentDate => deriveDisabledDate(currentDate)}
      />
    </Flex>
  )
}
