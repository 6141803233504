import { EducationalMaterial, FileEducationalMaterial } from "@interfaces*"
import { generateEducationalMaterialURL } from "@state/services/subApis/educationalMaterials/urls"
import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Modal } from "antd"
import { useAlert } from "@hooks"
import { defaultError } from "@helpers"

import { VideoPlayer } from "@components/VideoPlayer"

import style from "./style.m.scss"

const videoFormats = ["video/mp4", "video/webm", "video/ogg", "video/quicktime"]

type EducationalMaterialFileReaderProps = {
  material: EducationalMaterial
  isVideoLoading: boolean
  setIsVideoLoading: (isLoading: boolean) => void
}

export const EducationalMaterialFileReader: FC<EducationalMaterialFileReaderProps> = ({
  material,
  isVideoLoading,
  setIsVideoLoading
}) => {
  const { error } = useAlert()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = async () => {
    if (material.type === "file" && videoFormats.includes(String(material.contentType))) {
      setIsVideoLoading(true)
      try {
        setIsOpen(true)
      } catch {
        error(defaultError)
      } finally {
        setIsVideoLoading(false)
      }
    }
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const hostname = `${window.location.protocol}//${window.location.hostname}`

  return videoFormats.includes(String((material as FileEducationalMaterial).contentType)) ? (
    <>
      <Button type="link" onClick={handleOpen} style={{ paddingLeft: 0 }} disabled={isVideoLoading}>
        {material.name}
      </Button>
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
        closeIcon={false}
        className={style.videoModal}
      >
        <VideoPlayer url={`${hostname}/api${generateEducationalMaterialURL(material.id)}`} />
      </Modal>
    </>
  ) : (
    <Link
      to={`/api${generateEducationalMaterialURL(material.id)}`}
      target="_blank"
      className={style.link}
      data-testid="document-link"
    >
      {material.name}
    </Link>
  )
}
