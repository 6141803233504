import { lazy } from "react"
import { Abilities } from "@interfaces"
import OrgChart from "@app/sections/OrgChart"
import { Section } from "@enums"
import { Departments } from "@app/sections/Departments"
import { StaffEducationalMaterials } from "@app/sections/StaffEducationalMaterials"
import { HrDashboard } from "@app/sections/HrDashboard"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { permissionsMenuRoutes } from "./permissionsRoute"
import {
  DEPARTMENTS_ROUTE,
  USERS,
  VACATIONS_ROUTE,
  ORGCHART_ROUTE,
  PERMISSIONS,
  MY_PROFILE,
  SLACK_ROUTE,
  SETTINGS_ROUTE,
  DASHBOARD_ROUTE,
  MY_TEAM_ROUTE,
  EDUCATIONAL_MATERIALS_ROUTE,
  BANNED_IPS_ROUTE,
  PERSONAL_SETTINGS_ROUTE,
  HR_DASHBOARD_ROUTE
} from "./inboundRoutes"
import { settingsMenuRoutes } from "./settingsRoutes"
import { personalSettingsMenuRoutes } from "./personalSettingsRoutes"
import { slackMenuRoutes } from "./slackRoutes"
import { educationalMaterialsMenuRoutes } from "./educationalMaterialsRoutes"

const Users = lazy(() => import("@app/sections/Users"))
const Dashboard = lazy(() => import("@app/sections/Dashboard"))
const MyProfile = lazy(() => import("@app/sections/Profile"))
const MyTeam = lazy(() => import("@app/sections/MyTeam"))

const Vacations = lazy(() => import("@app/sections/Vacations"))
const BannedIps = lazy(() => import("@app/sections/BannedIps"))

export const mainMenuRoutes = (
  abilities: Abilities | null,
  isChief: boolean,
  isSuperaccess: boolean,
  withSubordinates: boolean
): Array<RouteItem> => {
  return [
    {
      path: DASHBOARD_ROUTE,
      title: "My Dashboard",
      slug: "home",
      component: Dashboard,
      componentWrap: DefaultWrapper
    },
    {
      path: HR_DASHBOARD_ROUTE,
      title: "HR dashboard",
      slug: "hrDashboard",
      component: HrDashboard,
      componentWrap: DefaultWrapper,
      hidden: !abilities?.hr_dashboard?.full_access
    },
    {
      path: MY_PROFILE,
      title: "My profile",
      slug: "myProfile",
      component: MyProfile,
      componentWrap: DefaultWrapper
    },
    {
      path: MY_TEAM_ROUTE,
      title: "My team",
      slug: "myTeam",
      component: MyTeam,
      componentWrap: DefaultWrapper,
      hidden: !(isChief && withSubordinates)
    },
    {
      path: ORGCHART_ROUTE,
      title: "Org chart",
      slug: "orgChart",
      component: OrgChart,
      hidden: false
    },
    {
      path: VACATIONS_ROUTE,
      title: "Vacations",
      slug: "vacations",
      component: Vacations,
      componentWrap: DefaultWrapper,
      hidden: !(abilities?.vacations?.basic_manage || abilities?.vacations?.manage)
    },
    {
      path: USERS,
      title: "Employees",
      slug: "users",
      component: Users,
      componentWrap: DefaultWrapper,
      hidden: !abilities?.users?.index
    },
    {
      path: DEPARTMENTS_ROUTE,
      title: "Departments",
      slug: "departments",
      component: Departments,
      componentWrap: DefaultWrapper,
      hidden: !abilities?.departments?.index
    },
    {
      path: SLACK_ROUTE,
      title: "Slack",
      slug: "slack",
      subRoutes: slackMenuRoutes(abilities),
      hidden: !abilities?.[Section.SlackGroups]?.read_all || !abilities?.[Section.SlackUsers]?.search
    },
    {
      path: EDUCATIONAL_MATERIALS_ROUTE,
      title: "Educational materials",
      slug: "educationalMaterials",
      component: abilities?.education_materials?.bulks_manage ? undefined : StaffEducationalMaterials,
      subRoutes: abilities?.education_materials?.bulks_manage ? educationalMaterialsMenuRoutes(abilities) : [],
      hidden: false
    },
    {
      path: PERSONAL_SETTINGS_ROUTE,
      title: "Personal settings",
      slug: "personalSettings",
      subRoutes: personalSettingsMenuRoutes,
      hidden: false
    },
    {
      path: PERMISSIONS,
      title: "Permissions",
      slug: "permissions",
      subRoutes: permissionsMenuRoutes(),
      hidden: !isSuperaccess
    },
    {
      path: SETTINGS_ROUTE,
      title: "Settings",
      slug: "settings",
      subRoutes: settingsMenuRoutes(abilities),
      hidden:
        !abilities?.vacations?.manage &&
        !abilities?.employment_settings?.manage &&
        !abilities?.locations?.index &&
        !abilities?.available_systems?.manage
    },
    {
      path: BANNED_IPS_ROUTE,
      title: "Banned IPs",
      slug: "bannedIps",
      component: BannedIps,
      componentWrap: DefaultWrapper,
      hidden: !abilities?.[Section.BannedIps]?.index
    },
    {
      path: "/sp",
      title: "Support Portal",
      slug: "supportPortal",
      disabled: true
    }
  ]
}
