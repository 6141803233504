import { doubleStableSortList } from "@helpers"
import { OrgChartPosition } from "@interfaces"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RawNodeDatum } from "react-d3-tree/lib/types/common"

type OrgChartState = {
  positions: Array<OrgChartPosition>
  currentTree: RawNodeDatum
  isTreeTop: boolean
}

const initialState: OrgChartState = {
  positions: [],
  currentTree: {} as RawNodeDatum,
  isTreeTop: false
}

const buildAttributes = (position: OrgChartPosition) => {
  return {
    id: position.id,
    isCeo: position.isCeo,
    userId: position.user ? position.user.id : "",
    firstName: position.user ? position.user.firstName : "",
    secondName: position.user ? position.user.secondName : "",
    lastName: position.user ? position.user.lastName : "",
    photo: position.user?.photo ? position.user.photo : "",
    positionName: position.positionName,
    childrenCount: position.children.length
  }
}

const buildChildren = (childrenIds: Array<string>, positions: Array<OrgChartPosition>) => {
  const mappedChildren = childrenIds.map(child => {
    const currentChild = positions.find(pos => pos.id === child)!

    return {
      name: currentChild.id,
      attributes: {
        ...buildAttributes(currentChild)
      },
      children: [] as Array<RawNodeDatum>
    }
  })

  return doubleStableSortList(
    mappedChildren,
    element => element.attributes.positionName,
    element => element.attributes.firstName
  )
}

const findAllParents = (userPositionId: string, positions: Array<OrgChartPosition>) => {
  const parentChain: Array<OrgChartPosition> = []

  const findParent = (currentId: string): void => {
    const currentParent = positions.find(pos => pos.children.includes(currentId))

    if (currentParent) {
      parentChain.push(currentParent)
      findParent(currentParent.id)
    }
  }

  findParent(userPositionId)

  return parentChain
}

const buildStartedTree = (userPositionId: string, positions: Array<OrgChartPosition>) => {
  if (!positions.length) {
    return undefined
  }

  const parent = positions.find(pos => pos.children.includes(userPositionId))!
  const currentPos = positions.find(pos => pos.id === userPositionId)

  if (!currentPos) {
    return undefined
  }

  const children = buildChildren(currentPos.children, positions)

  if (currentPos.isCeo) {
    return {
      name: currentPos.id,
      attributes: buildAttributes(currentPos),
      children
    }
  }

  let tree: RawNodeDatum = {
    name: String(parent?.id),
    attributes: buildAttributes(parent),
    children: [
      {
        name: currentPos.id,
        attributes: buildAttributes(currentPos),
        children
      }
    ]
  }

  const parentsChain = findAllParents(userPositionId, positions).slice(1)

  parentsChain.forEach(pos => {
    const node = {
      name: String(pos?.id),
      attributes: buildAttributes(pos),
      children: [{ ...tree }]
    }

    tree = node
  })

  return tree
}

const moveDownTheTree = (userPositionId: string, currentTree: RawNodeDatum, positions: Array<OrgChartPosition>) => {
  if (!currentTree.children) {
    return undefined
  }

  return buildStartedTree(userPositionId, positions)
}

const orgChartSlice = createSlice({
  name: "orgChart",
  initialState,
  reducers: {
    setPositions(state, action: PayloadAction<{ positions: Array<OrgChartPosition> }>) {
      state.positions = action.payload.positions
    },

    setStartedTree(state, action: PayloadAction<{ currentUserId: string }>) {
      if (!state.positions.length) return
      const currentPos =
        state.positions.find(pos => pos.user?.id === action.payload.currentUserId) ??
        state.positions.find(pos => pos.isCeo)!
      const currentUser = state.positions.find(pos => pos.id === currentPos.id)
      state.isTreeTop = !!currentUser?.isCeo
      const startedTree = buildStartedTree(currentPos.id, state.positions)
      if (startedTree) state.currentTree = startedTree
    },

    downTheTree(state, action: PayloadAction<{ currentPosId: string }>) {
      const newTree = moveDownTheTree(action.payload.currentPosId, state.currentTree, state.positions)
      if (newTree) state.currentTree = newTree
      state.isTreeTop = false
    }
  }
})

export const orgChartActions = orgChartSlice.actions

export default orgChartSlice.reducer
