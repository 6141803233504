import React, { FC } from "react"
import { OrgChartNodeAttributes } from "@interfaces"
import { useAppDispatch } from "@hooks"
import { orgChartActions } from "@state/reducers/OrgChart"
import { Button, Card, Divider, Flex } from "antd"
import Meta from "antd/es/card/Meta"
import { DownOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { generateUserProfileRoute } from "@routes/inboundRoutes"

import { PhotoViewer } from "@components/PhotoViewer"

import style from "./style.m.scss"

type Props = {
  attributes: OrgChartNodeAttributes
  centerNode: () => void
}

const OrgChartCard: FC<Props> = ({ attributes, centerNode }) => {
  const dispatch = useAppDispatch()

  const handleDownTheTree = () => {
    dispatch(orgChartActions.downTheTree({ currentPosId: attributes.id }))
    centerNode()
  }

  return (
    <>
      <Card
        className={style.cardContainer}
        bordered
        style={{ position: "unset" }}
        cover={<PhotoViewer src={attributes.photo} />}
      >
        <Flex justify="space-between" align="center">
          {attributes.userId ? (
            <Link to={generateUserProfileRoute(attributes.userId)}>
              <Meta
                title={
                  <div style={{ whiteSpace: "normal" }} className={style.metaText}>
                    <div>{attributes.firstName}</div>
                    <div>{attributes.lastName}</div>
                  </div>
                }
                description={<div className={style.metaText}>{attributes.positionName}</div>}
              />
            </Link>
          ) : (
            <Meta
              title={
                <div style={{ whiteSpace: "normal" }} className={style.metaText}>
                  Not assigned yet
                </div>
              }
              description={<div className={style.metaText}>{attributes.positionName}</div>}
            />
          )}
          <Divider type="vertical" style={{ position: "unset" }} />
          {!!attributes.childrenCount && (
            <Flex vertical>
              <div className={style.childrenCount} style={{ whiteSpace: "normal" }}>
                {attributes.childrenCount}
              </div>
              <Button
                style={{ position: "unset" }}
                type="text"
                size="large"
                icon={<DownOutlined />}
                onClick={handleDownTheTree}
              />
            </Flex>
          )}
        </Flex>
      </Card>
    </>
  )
}

export default OrgChartCard
