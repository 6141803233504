import React, { FC } from "react"
import ReactPlayer from "react-player"

import style from "./style.m.scss"

type VideoPlayerProps = {
  url: string
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ url }) => (
  <div className={style.playerWrapper}>{url && <ReactPlayer url={url} controls width="100%" preload="none" />}</div>
)
