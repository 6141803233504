import backendApi from "@state/services/backendApi"

import { fetchDashboardData } from "./GET/fetchDashboardData"
import { fetchAttritionRateData } from "./GET/fetchAttritionRateData"

export const hrDashboardApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchDashboardData: fetchDashboardData(build),
    fetchAttritionRateData: fetchAttritionRateData(build)
  })
})
