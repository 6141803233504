import { EmployeeMetrics } from "@interfaces*"
import { Button, Flex } from "antd"
import React, { FC } from "react"
import { useAppSelector } from "@hooks"
import dayjs from "dayjs"
import { useNavigate } from "react-router"
import { USERS } from "@routes/inboundRoutes"

import { Card } from "../../components"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type EmployeeMetricsCardProps = {
  employeeMetrics: EmployeeMetrics
}

export const EmployeeMetricsCard: FC<EmployeeMetricsCardProps> = ({ employeeMetrics }) => {
  const navigate = useNavigate()

  const { endDate, product } = useAppSelector(state => state.hrDashboardSlice.filters)

  const tooltipText = () => {
    if (!endDate) return `Employee metrics for the date: ${dayjs().format("DD, MMM, YY")}`
    const currentMonth = dayjs().format("MMM, YY")
    const pickedMonth = dayjs(endDate).format("MMM, YY")

    return `Employee metrics for the date: ${
      currentMonth !== pickedMonth ? dayjs(endDate).endOf("month").format("DD, MMM, YY") : dayjs().format("DD, MMM, YY")
    }`
  }

  const handleClick = (employees: Array<number>, employeeType: string | null) => {
    navigate(USERS, { state: { searchedUsers: employees, product: product === "all" ? null : product, employeeType } })
  }

  const isRedirectDisabled = !dayjs(endDate).isSame(dayjs(), "day") && !!endDate

  return (
    <Card tooltipText={tooltipText()}>
      <Title level={3}>Employee metrics</Title>
      <Flex className={style.percentage} vertical gap={10} align="center">
        <Flex justify="space-between" align="center" className={style.stringContainer}>
          <div className={style.counter}>Total</div>
          <div className={style.counter}>{employeeMetrics.total_employees.length}</div>
        </Flex>
        <Flex justify="space-between" align="center" className={style.stringContainer}>
          <Button
            onClick={() => handleClick(employeeMetrics.employees_type, "employee")}
            type="link"
            className={style.title}
            disabled={isRedirectDisabled || !employeeMetrics.employees_type.length}
          >
            Employees
          </Button>
          <div className={style.counter}>{employeeMetrics.employees_type.length}</div>
        </Flex>
        <Flex justify="space-between" align="center" className={style.stringContainer}>
          <Button
            onClick={() => handleClick(employeeMetrics.eors_type, "eor")}
            type="link"
            className={style.title}
            disabled={isRedirectDisabled || !employeeMetrics.eors_type.length}
          >
            EORs
          </Button>
          <div className={style.counter}>{employeeMetrics.eors_type.length}</div>
        </Flex>
        <Flex justify="space-between" align="center" className={style.stringContainer}>
          <Button
            onClick={() => handleClick(employeeMetrics.contractors_type, "contractor")}
            type="link"
            className={style.title}
            disabled={isRedirectDisabled || !employeeMetrics.contractors_type.length}
          >
            Contractors
          </Button>
          <div className={style.counter}>{employeeMetrics.contractors_type.length}</div>
        </Flex>
      </Flex>
    </Card>
  )
}
