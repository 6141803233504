export const GRAPH_COLORS = {
  ORANGE: "rgb(255, 165, 0)",
  TRANSPARENT_ORANGE: "rgba(255, 165, 0, 0.2)",
  RED: "rgb(255, 99, 132)",
  TRANSPARENT_RED: "rgba(255, 99, 132, 0.2)",
  BLUE: "rgb(54, 162, 235)",
  TRANSPARENT_BLUE: "rgba(54, 162, 235, 0.2)",
  GREEN: "rgb(75, 192, 192)",
  TRANSPARENT_GREEN: "rgba(75, 192, 192, 0.2)"
}
