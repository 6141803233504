import React from "react"
import { CustomNodeElementProps } from "react-d3-tree/lib/types/common"
import { Coordinates, OrgChartNodeAttributes } from "@interfaces*"

import OrgChartCard from "../OrgChartCard"

const useOrgChartSvgNode = (setCoords: (coords: Coordinates) => void) => {
  return (customProps: CustomNodeElementProps) => {
    const { nodeDatum, toggleNode, hierarchyPointNode } = customProps
    const { attributes } = nodeDatum

    const handleCenterNode = () => {
      setCoords({ x: hierarchyPointNode.x, y: hierarchyPointNode.y, depth: hierarchyPointNode.depth })
    }

    return (
      <g onClick={toggleNode}>
        <foreignObject x="-175" y="-325" width="400" height="750">
          <OrgChartCard attributes={attributes as OrgChartNodeAttributes} centerNode={handleCenterNode} />
        </foreignObject>
      </g>
    )
  }
}
export default useOrgChartSvgNode
