import React, { Children, FC } from "react"
import { Col, Row } from "antd"
import { Gutter } from "antd/es/grid/row"

import style from "./style.m.scss"

const GUTTER_SIZE = 16
const gutter: [Gutter, Gutter] = [GUTTER_SIZE, GUTTER_SIZE]

export const Stack: FC = ({ children }) => {
  const childElements = Children.toArray(children).filter(item => React.isValidElement(item))

  return (
    <Row gutter={gutter}>
      {Children.map(childElements, child => (
        <Col span={6} className={style.cell}>
          {child}
        </Col>
      ))}
    </Row>
  )
}
