import dayjs from "dayjs"

export const deriveGraphLabels = (startDate: string, endDate: string) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  const monthDiff = end.diff(start, "month") + 1

  const months = Array.from({ length: monthDiff }, (_, i) => start.add(i, "month").format("MMM, YY"))

  return months
}
