import { Rule } from "antd/es/form"

export const requiredRule: Rule = { required: true, message: "Can't be blank" }

export const emailRule: Rule = {
  type: "email",
  message: "Incorrect email format"
}

export const urlRule: Rule = { type: "url", message: "Must be a valid url" }
