import { useState } from "react"
import { Paging } from "@interfaces"
import { STANDART_PAGE_NUMBER, STANDART_RES_SIZE } from "@helpers"

type ListViewState<T extends object> = {
  filters: T
  paging: Paging
}

export const useListViewState = <T extends object>(initialFiltersValues: T) => {
  const [state, setState] = useState<ListViewState<T>>({
    filters: initialFiltersValues,
    paging: { page: STANDART_PAGE_NUMBER, size: STANDART_RES_SIZE }
  })

  const handleFiltersChange = (updater: Partial<T> | ((oldFilters: T) => T)) => {
    // this case if for useFilterAndParamsSync hook call
    if (typeof updater === "function") {
      setState(oldState => ({ ...oldState, filters: updater(oldState.filters) }))
      return
    }

    setState(oldState => ({
      filters: { ...oldState.filters, ...updater },
      paging: { ...oldState.paging, page: 1 }
    }))
  }

  const handlePageNumberChange = (updatedPageNumber: number) => {
    setState(oldState => ({ ...oldState, paging: { ...oldState.paging, page: updatedPageNumber } }))
  }

  const handlePageSizeChange = (updatedPageSize: number) => {
    setState(oldState => ({ ...oldState, paging: { page: 1, size: updatedPageSize } }))
  }

  const handlePagingChange = (updatedPageNumber: number, updatedPageSize: number) =>
    setState(oldState => ({
      ...oldState,
      paging: { page: oldState.paging.size === updatedPageSize ? updatedPageNumber : 1, size: updatedPageSize }
    }))

  const handleClearFilters = () => {
    setState({
      filters: { ...initialFiltersValues, ids: [], employeeTypes: [] },
      paging: { page: STANDART_PAGE_NUMBER, size: STANDART_RES_SIZE }
    })
  }

  return {
    filters: state.filters,
    paging: state.paging,
    onFiltersChange: handleFiltersChange,
    onPageNumberChange: handlePageNumberChange,
    onPageSizeChange: handlePageSizeChange,
    onPagingChange: handlePagingChange,
    onClearFilters: handleClearFilters
  }
}
