import { AttritionRateStatisticsTableEntity } from "@state/types/hrDashboard"
import { Table } from "antd"
import dayjs from "dayjs"
import React, { FC } from "react"

import style from "./style.m.scss"

type AttritionRateTableProps = {
  tableData: Array<AttritionRateStatisticsTableEntity>
}

const rowData = [
  { key: "begin", label: "Beginning of the month" },
  { key: "attrition", label: "Attrition" },
  { key: "addition", label: "Addition" },
  { key: "end", label: "End of the month" },
  { key: "rate", label: "Attrition rate" }
]

export const AttritionRateTable: FC<AttritionRateTableProps> = ({ tableData }) => {
  const transformedData = rowData.map((row, rowIndex) => {
    const rowObject: { [k: string]: number | string } = { key: rowIndex, label: row.label }

    tableData.forEach((item, colIndex) => {
      if (row.key === "begin") rowObject[`month_${colIndex}`] = item.count_employees_at_begin
      if (row.key === "attrition") rowObject[`month_${colIndex}`] = item.attrition
      if (row.key === "addition") rowObject[`month_${colIndex}`] = item.addition
      if (row.key === "end") rowObject[`month_${colIndex}`] = item.count_employees_at_end
      if (row.key === "rate") rowObject[`month_${colIndex}`] = item.rate
    })

    return rowObject
  })

  return (
    <Table
      className={style.table}
      columns={[
        {
          title: <div className={style.headerTitle}>Number of employees</div>,
          dataIndex: "label",
          key: "label",
          fixed: "left",
          width: "200px"
        },
        ...tableData.map((item, index) => ({
          title: dayjs(item.date).format("MMM, YY"),
          children: [
            {
              title: "",
              dataIndex: `month_${index}`,
              key: `month_${index}`
            }
          ]
        }))
      ]}
      scroll={{ x: "max-content" }}
      dataSource={transformedData}
      pagination={false}
      bordered
    />
  )
}
