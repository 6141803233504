export enum ProjectsSectionAbility {
  Index = "index",
  Show = "show",
  Update = "update",
  Create = "create",
  Destroy = "destroy"
}

export enum ProjectsTeamsSectionAbility {
  Show = "show",
  AddMembers = "add_members",
  RemoveMembers = "remove_members"
}

export enum ProjectsLeadsSectionAbility {
  Index = "index",
  Update = "update"
}

export enum ProjectsLogsSectionAbility {
  Index = "index",
  Show = "show"
}

export enum ProjectsHistorySectionAbility {
  Show = "show"
}

export enum UsersSectionAbility {
  ShowUsers = "show_users",
  CreateUser = "create_user"
}

export enum ProfilesSectionAbility {
  Show = "show",
  Update = "update"
}

export enum VacationsSectionAbility {
  BasicManage = "basic_manage",
  Manage = "manage"
}

export enum OrgChartSectionAbility {
  ShowOrgChart = "show_orgChart"
}

export enum InternshipSectionAbility {
  ShowInternship = "show_internship",
  ShowMentors = "show_mentors",
  ShowMentorshipHistory = "show_mentorship_history",
  CreateMentor = "create_mentor",
  ExcludeMentor = "exclude_mentor",
  ChangeMentorType = "change_mentor_type",
  ChangeIntern = "change_intern",
  ShowInterns = "show_interns",
  CreateIntern = "create_intern",
  ExcludeIntern = "exclude_intern",
  ChangeMentor = "change_mentor",
  IncreaseWorkingHours = "increase_working_hours",
  IncreaseInternshipLevel = "increase_internship_level",
  StartInternship = "start_internship"
}

export enum InternsSectionAbility {
  IncreaseStage = "increase_stage",
  IncreaseWorkingHours = "increase_working_hours",
  StartInternship = "start_internship",
  FinishiInternship = "finish_internship",
  ExcludeIntern = "exclude",
  ChangeMentor = "change_mentor",
  Index = "index",
  CreateInterns = "create"
}

export enum MentorsSectionAbility {
  ExcludeMentor = "exclude",
  ChangeMentorType = "change_mentor_type",
  ChangeIntern = "change_intern",
  Index = "index",
  CreateMentors = "create"
}

export enum AbilitiesSectionAbility {
  ShowAbilities = "show_abilities",
  EditAbilities = "edit_abilities"
}

export enum PermissionsSectionAbility {
  ShowPermissions = "show_permissions",
  ShowRelationships = "show_relationships",
  AddRelationship = "add_relationship",
  EditRelationship = "edit_relationship",
  EditUserPermissions = "edit_user_permissions",
  DeleteRelationship = "delete_relationship",
  ShowPermissionsUsers = "show_permissions_users"
}

export enum RelationshipSectionAbility {
  Index = "index",
  Create = "create",
  Update = "update",
  Destroy = "destroy"
}

export enum AbilityScope {
  Personal = "Personal",
  Subordinate = "Subordinate",
  Associated = "Associated"
}

export enum VacationsAssignmentHrsSectionAbility {
  Index = "index",
  Available = "available",
  Show = "show",
  Create = "create",
  Update = "update",
  Destroy = "destroy"
}

export enum SlackGroupsSectionAbility {
  ReadAll = "read_all",
  EditAll = "edit_all"
}

export enum SlackUsersAbility {
  EditAll = "edit_all",
  Search = "search"
}

export enum UserProfileEmploymentInfoSectionAbility {
  ReadAll = "read_all",
  UpdateAll = "update_all"
}

export enum EducationalMaterialsAbility {
  ReadAll = "read_all",
  EditAll = "edit_all",
  BulksManage = "bulks_manage"
}

export enum UserProfileJobHistorySectionAbility {
  ReadAll = "read_all"
}

export enum UserProfileSalariesHistorySectionAbility {
  Index = "index",
  Create = "create",
  Destroy = "destroy",
  ReadAll = "read_all",
  EditAll = "edit_all"
}

export enum CertificatesAbility {
  ReadAll = "read_all"
}

export enum ConferencesAbilities {
  EditAll = "edit_all"
}

export enum PerformanceReviewsAbility {
  ReadAll = "read_all",
  EditAll = "edit_all"
}

export enum BannedIpsAbility {
  Index = "index",
  Destroy = "destroy"
}

export enum PersonalSettingsAbility {
  EditAll = "edit_all"
}

export enum UserCommissionAbility {
  ReadAll = "read_all"
}

export enum AvailableSystemAbility {
  Manage = "manage"
}

export enum HrDashboardAbility {
  FullAccess = "full_access"
}
