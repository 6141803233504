import { Section } from "@enums"
import { Abilities, Ability } from "@interfaces"

const initialAbilities = {
  [Section.Projects]: null,
  [Section.Teams]: null,
  [Section.Leads]: null,
  [Section.ProjectsLogs]: null,
  [Section.ProjectsHistory]: null,
  [Section.Vacations]: null,
  [Section.Users]: null,
  [Section.Profile]: null,
  [Section.Internship]: null,
  [Section.Interns]: null,
  [Section.Mentors]: null,
  [Section.OrgChart]: null,
  [Section.Abilities]: null,
  [Section.Permissions]: null,
  [Section.VacationsHolidays]: null,
  [Section.Relationships]: null,
  [Section.Departments]: null,
  [Section.Roles]: null,
  [Section.VacationsAssignmentHrs]: null,
  [Section.SlackGroups]: null,
  [Section.SlackUsers]: null,
  [Section.UserProfileEmploymentInfo]: null,
  [Section.Locations]: null,
  [Section.EmploymentSettings]: null,
  [Section.Salaries]: null,
  [Section.JobHistory]: null,
  [Section.Certificates]: null,
  [Section.PerformanceReviews]: null,
  [Section.Conferences]: null,
  [Section.VacationSettings]: null,
  [Section.EducationalMaterials]: null,
  [Section.BannedIps]: null,
  [Section.PersonalSettings]: null,
  [Section.UserCommission]: null,
  [Section.AvailableSystem]: null,
  [Section.VacationAccumulations]: null,
  [Section.HrDashboard]: null
}

/* eslint max-lines-per-function: ["error", 280] */
// eslint-disable-next-line
export const abilitiesTransformer = (response: Array<Ability>) => {
  const abilities = response.reduce((abilitiesState: Abilities, currentAbility: Ability) => {
    switch (currentAbility.section) {
      case Section.Projects:
        return {
          ...abilitiesState,
          [Section.Projects]: { ...abilitiesState[Section.Projects], [currentAbility.name]: currentAbility }
        }
      case Section.Teams:
        return {
          ...abilitiesState,
          [Section.Teams]: { ...abilitiesState[Section.Teams], [currentAbility.name]: currentAbility }
        }
      case Section.Leads:
        return {
          ...abilitiesState,
          [Section.Leads]: { ...abilitiesState[Section.Leads], [currentAbility.name]: currentAbility }
        }
      case Section.ProjectsLogs:
        return {
          ...abilitiesState,
          [Section.ProjectsLogs]: { ...abilitiesState[Section.ProjectsLogs], [currentAbility.name]: currentAbility }
        }
      case Section.ProjectsHistory:
        return {
          ...abilitiesState,
          [Section.ProjectsHistory]: {
            ...abilitiesState[Section.ProjectsHistory],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.Vacations:
        return {
          ...abilitiesState,
          [Section.Vacations]: { ...abilitiesState[Section.Vacations], [currentAbility.name]: currentAbility }
        }
      case Section.VacationsHolidays:
        return {
          ...abilitiesState,
          [Section.VacationsHolidays]: {
            ...abilitiesState[Section.VacationsHolidays],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.OrgChart:
        return {
          ...abilitiesState,
          [Section.OrgChart]: { ...abilitiesState[Section.OrgChart], [currentAbility.name]: currentAbility }
        }
      case Section.Users:
        return {
          ...abilitiesState,
          [Section.Users]: { ...abilitiesState[Section.Users], [currentAbility.name]: currentAbility }
        }
      case Section.Profile:
        return {
          ...abilitiesState,
          [Section.Profile]: { ...abilitiesState[Section.Profile], [currentAbility.name]: currentAbility }
        }
      case Section.Internship:
        return {
          ...abilitiesState,
          [Section.Internship]: { ...abilitiesState[Section.Internship], [currentAbility.name]: currentAbility }
        }
      case Section.Interns:
        return {
          ...abilitiesState,
          [Section.Interns]: { ...abilitiesState[Section.Interns], [currentAbility.name]: currentAbility }
        }
      case Section.Mentors:
        return {
          ...abilitiesState,
          [Section.Mentors]: { ...abilitiesState[Section.Mentors], [currentAbility.name]: currentAbility }
        }
      case Section.Abilities:
        return {
          ...abilitiesState,
          [Section.Abilities]: { ...abilitiesState[Section.Abilities], [currentAbility.name]: currentAbility }
        }
      case Section.Permissions:
        return {
          ...abilitiesState,
          [Section.Permissions]: { ...abilitiesState[Section.Permissions], [currentAbility.name]: currentAbility }
        }
      case Section.Relationships:
        return {
          ...abilitiesState,
          [Section.Relationships]: { ...abilitiesState[Section.Relationships], [currentAbility.name]: currentAbility }
        }
      case Section.Departments:
        return {
          ...abilitiesState,
          [Section.Departments]: { ...abilitiesState[Section.Departments], [currentAbility.name]: currentAbility }
        }
      case Section.Roles:
        return {
          ...abilitiesState,
          [Section.Roles]: { ...abilitiesState[Section.Roles], [currentAbility.name]: currentAbility }
        }
      case Section.VacationsAssignmentHrs:
        return {
          ...abilitiesState,
          [Section.VacationsAssignmentHrs]: {
            ...abilitiesState[Section.VacationsAssignmentHrs],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.SlackGroups:
        return {
          ...abilitiesState,
          [Section.SlackGroups]: {
            ...abilitiesState[Section.SlackGroups],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.SlackUsers:
        return {
          ...abilitiesState,
          [Section.SlackUsers]: {
            ...abilitiesState[Section.SlackUsers],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.UserProfileEmploymentInfo:
        return {
          ...abilitiesState,
          [Section.UserProfileEmploymentInfo]: {
            ...abilitiesState[Section.UserProfileEmploymentInfo],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.Locations:
        return {
          ...abilitiesState,
          [Section.Locations]: {
            ...abilitiesState[Section.Locations],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.EmploymentSettings:
        return {
          ...abilitiesState,
          [Section.EmploymentSettings]: {
            ...abilitiesState[Section.EmploymentSettings],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.VacationSettings:
        return {
          ...abilitiesState,
          [Section.VacationSettings]: {
            ...abilitiesState[Section.VacationSettings],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.EducationalMaterials:
        return {
          ...abilitiesState,
          [Section.EducationalMaterials]: {
            ...abilitiesState[Section.EducationalMaterials],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.Salaries:
        return {
          ...abilitiesState,
          [Section.Salaries]: {
            ...abilitiesState[Section.Salaries],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.JobHistory:
        return {
          ...abilitiesState,
          [Section.JobHistory]: {
            ...abilitiesState[Section.JobHistory],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.Certificates:
        return {
          ...abilitiesState,
          [Section.Certificates]: {
            ...abilitiesState[Section.Certificates],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.PerformanceReviews:
        return {
          ...abilitiesState,
          [Section.PerformanceReviews]: {
            ...abilitiesState[Section.PerformanceReviews],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.Conferences:
        return {
          ...abilitiesState,
          [Section.Conferences]: {
            ...abilitiesState[Section.Conferences],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.BannedIps:
        return {
          ...abilitiesState,
          [Section.BannedIps]: {
            ...abilitiesState[Section.BannedIps],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.PersonalSettings:
        return {
          ...abilitiesState,
          [Section.PersonalSettings]: {
            ...abilitiesState[Section.PersonalSettings],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.UserCommission:
        return {
          ...abilitiesState,
          [Section.UserCommission]: { ...abilitiesState[Section.UserCommission], [currentAbility.name]: currentAbility }
        }
      case Section.AvailableSystem:
        return {
          ...abilitiesState,
          [Section.AvailableSystem]: {
            ...abilitiesState[Section.AvailableSystem],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.VacationAccumulations:
        return {
          ...abilitiesState,
          [Section.VacationAccumulations]: {
            ...abilitiesState[Section.VacationAccumulations],
            [currentAbility.name]: currentAbility
          }
        }
      case Section.HrDashboard:
        return {
          ...abilitiesState,
          [Section.HrDashboard]: {
            ...abilitiesState[Section.HrDashboard],
            [currentAbility.name]: currentAbility
          }
        }
      default:
        return abilitiesState
    }
  }, initialAbilities)

  return abilities
}
