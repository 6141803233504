import { combineReducers } from "redux"
import { autoBatchEnhancer, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"
import appReducer from "@state/reducers/App"
import meReducer from "@state/reducers/me"
import myAbilitiesReducer from "@state/reducers/myAbilities"
import vacationsReducer from "@state/reducers/Vacations"
import orgChartReducer from "@state/reducers/OrgChart"
import newPolicyReducer from "@state/reducers/newPolicy"
import newGroupReducer from "@state/reducers/newGroup"
import manageGroupsSlice from "@state/reducers/manageGroups"
import manageUsersPermissionsSlice from "@state/reducers/manageUsersPermissions"
import personalSettingsSlice from "@state/reducers/personalSettings"
import authReducer from "@state/reducers/auth"
import dashboardReducer from "@state/reducers/dashboard"
import hrDashboardSlice from "@state/reducers/hrDashboard"
import { configureReduxEnhancers } from "sentry/utils"

import backendApi from "./services/backendApi"
import { RESET_ALL_ACTION_TYPE } from "./const"

const reducer = combineReducers({
  appReducer,
  meReducer,
  myAbilitiesReducer,
  vacationsReducer,
  orgChartReducer,
  newPolicyReducer,
  newGroupReducer,
  manageGroupsSlice,
  manageUsersPermissionsSlice,
  personalSettingsSlice,
  authReducer,
  dashboardReducer,
  hrDashboardSlice,
  [backendApi.reducerPath]: backendApi.reducer
})

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer", "meReducer"]
}

const rootReducer: typeof reducer = (state, action) => {
  if (action.type === RESET_ALL_ACTION_TYPE) {
    return reducer(undefined, action)
  }

  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(backendApi.middleware),
  enhancers: existingEnhancers =>
    configureReduxEnhancers(existingEnhancers).concat(autoBatchEnhancer({ type: "timer", timeout: 500 }))
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
