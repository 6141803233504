import React, { FC, useState } from "react"
import { Flex } from "antd"
import { useAppDispatch, useAppSelector } from "@hooks"
import { hrDashboardActions } from "@state/reducers/hrDashboard"
import { HrDashboardSraristicCards } from "@enums"
import cn from "classnames"
import { initDateNow } from "@helpers"
import dayjs from "dayjs"

import { Card } from "../../components"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type AttritionRateCardProps = {
  attritionRate: number
}

const takeToday = initDateNow()

const deriveDateForTooltip = (date: string | null) => dayjs(date).format("MMM, YY")

export const AttritionRateCard: FC<AttritionRateCardProps> = ({ attritionRate }) => {
  const dispatch = useAppDispatch()

  const { startDate, endDate } = useAppSelector(state => state.hrDashboardSlice.filters)

  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    const startOfPeriod = dayjs().subtract(1, "year")

    setIsActive(true)
    dispatch(hrDashboardActions.setCurrentBoard({ board: HrDashboardSraristicCards.AttritionRate }))
    dispatch(
      hrDashboardActions.setAttritionRatePeriod({
        startDate: startOfPeriod.startOf("month").format(),
        endDate: takeToday().startOf("month").format()
      })
    )
  }

  const tooltipText =
    startDate && endDate ? (
      <span>
        Attrition rate for the period: <br />
        {deriveDateForTooltip(startDate) === deriveDateForTooltip(endDate) ||
        deriveDateForTooltip(startDate) === dayjs(endDate).subtract(1, "month").format("MMM, YY")
          ? deriveDateForTooltip(startDate)
          : `${dayjs(startDate).format("MMM, YY")} - ${dayjs(endDate).subtract(1, "month").format("MMM, YY")}`}
      </span>
    ) : (
      <span>{deriveDateForTooltip(dayjs().subtract(1, "month").format())}</span>
    )

  return (
    <Card className={cn(style.card, isActive && style.active)} onClick={handleClick} tooltipText={tooltipText}>
      <Title level={3}>Attrition rate</Title>
      <Flex className={style.percentage}>{attritionRate}%</Flex>
    </Card>
  )
}
