import React, { FC } from "react"
import { hrDashboardApi } from "@state/services/subApis"
import { useAppSelector } from "@hooks"
import { HrDashboardSraristicCards } from "@enums"

import { SectionWrapper } from "@components/SectionWrapper"
import { Title } from "@components/Title"

import { HrDashboardCards } from "./HrDashboardCards"
import { HrDashboardFilter } from "./HrDashboardFilter"
import { AttritionRateBoard } from "./boards"

export const HrDashboard: FC = () => {
  const { filters, currentBoard } = useAppSelector(state => state.hrDashboardSlice)

  const { data: hrDashboardData } = hrDashboardApi.endpoints.fetchDashboardData.useQuery(filters)

  return (
    <SectionWrapper>
      <Title>HR Dashboard</Title>
      <HrDashboardFilter />
      {hrDashboardData && (
        <HrDashboardCards
          attritionRateData={hrDashboardData.attritionRate}
          employeeMetrics={hrDashboardData.employeesMetrics}
        />
      )}
      {currentBoard === HrDashboardSraristicCards.AttritionRate && <AttritionRateBoard />}
    </SectionWrapper>
  )
}
