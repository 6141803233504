import { Button, Flex } from "antd"
import React, { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@hooks"
import dayjs from "dayjs"
import { useNavigate } from "react-router"
import { USERS } from "@routes/inboundRoutes"
import { hrDashboardApi } from "@state/services/subApis"
import { hrDashboardActions } from "@state/reducers/hrDashboard"

import { Card } from "../../components"

import { Title } from "@components/Title"

import style from "./style.m.scss"

export const TrainingUndertakenCard: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isDateChanged, startDate, endDate, product } = useAppSelector(state => state.hrDashboardSlice.filters)

  const { trainingUndertaken } = hrDashboardApi.endpoints.fetchDashboardData.useQuery(
    {
      startDate: isDateChanged ? startDate : dayjs().startOf("year").format(),
      endDate: isDateChanged ? endDate : dayjs().format(),
      product
    },
    {
      selectFromResult: ({ data }) => ({
        trainingUndertaken: data?.trainingStatistics
      })
    }
  )

  useEffect(() => {
    return () => {
      dispatch(hrDashboardActions.setIsDatesChanges(false))
    }
  }, [])

  const tooltipText = () => {
    if (!isDateChanged) return `Training undertaken for period: ${dayjs().format("YYYY")} year`
    if (!endDate) return `Training undertaken for period: ${dayjs().format("DD, MMM, YY")}`
    if (dayjs(startDate).format("MMM, YY") === dayjs(endDate).format("MMM, YY"))
      return `Training undertaken for period: ${dayjs(startDate).format("MMM, YY")}`

    return `Training undertaken for period: ${dayjs(startDate).format("MMM, YY")} - ${dayjs(endDate).format("MMM, YY")}`
  }

  const handleClick = (users: Array<string>) => {
    navigate(USERS, { state: { searchedUsers: users, product: product !== "all" ? product : null } })
  }

  return (
    <Card tooltipText={tooltipText()}>
      <Title level={3}>Training undertaken</Title>
      <Flex className={style.percentage} vertical gap={10} align="center">
        {trainingUndertaken && (
          <Flex justify="space-between" align="center" className={style.stringContainer}>
            <Button
              onClick={() => handleClick(trainingUndertaken.completed)}
              type="link"
              className={style.title}
              disabled={!trainingUndertaken.completed.length}
            >
              Completed
            </Button>
            <div className={style.counter}>{trainingUndertaken.completed.length}</div>
          </Flex>
        )}
        {trainingUndertaken && (
          <Flex justify="space-between" align="center" className={style.stringContainer}>
            <Button
              onClick={() => handleClick(trainingUndertaken.in_progress)}
              type="link"
              className={style.title}
              disabled={!trainingUndertaken.in_progress.length}
            >
              In progress
            </Button>
            <div className={style.counter}>{trainingUndertaken.in_progress.length}</div>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}
