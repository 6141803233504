import { BoardView, HrDashboardSraristicCards } from "@enums"
import { DateUnit, initDateNow } from "@helpers"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

const takeToday = initDateNow()

type HrDashboardState = {
  currentBoard: HrDashboardSraristicCards | null
  filters: {
    startDate: string | null
    endDate: string | null
    product: string
    isDateChanged: boolean
  }
  attritionRateBoard: {
    boardView: BoardView
    startDate: string
    endDate: string
    product: string
  }
}

const initialState: HrDashboardState = {
  currentBoard: null,
  filters: {
    startDate: null,
    endDate: null,
    product: "all",
    isDateChanged: false
  },
  attritionRateBoard: {
    boardView: BoardView.Graph,
    startDate: takeToday().startOf("month").format(),
    endDate: takeToday().format(),
    product: "all"
  }
}

const hrDashboardSlice = createSlice({
  name: "hrDashboard",
  initialState,
  reducers: {
    setThisYear: state => {
      const today = takeToday().format()
      state.filters.startDate = dayjs(today).startOf("year").format()
      state.filters.endDate = dayjs(today).format()
    },

    setLastMonth: state => {
      const today = takeToday().format()
      state.filters.startDate = dayjs(today).add(-1, DateUnit.Month).startOf("month").format()
      state.filters.endDate = dayjs(today).add(-1, DateUnit.Month).endOf("month").format()
    },

    setThisMonth: state => {
      const today = takeToday().format()
      state.filters.startDate = dayjs(today).startOf("month").format()
      state.filters.endDate = today
    },

    setProduct: (state, { payload }: PayloadAction<{ product: string }>) => {
      state.filters.product = payload.product
    },

    setCurrentBoard: (state, { payload }: PayloadAction<{ board: HrDashboardSraristicCards }>) => {
      state.currentBoard = payload.board
    },

    changeDateRange(state, action: PayloadAction<{ startDate: string; endDate: string }>) {
      state.filters.startDate = action.payload.startDate
      state.filters.endDate = action.payload.endDate
    },

    setIsDatesChanges(state, action: PayloadAction<boolean>) {
      state.filters.isDateChanged = action.payload
    },

    setAttritionRateBoardView(state, action: PayloadAction<BoardView>) {
      if (action.payload !== state.attritionRateBoard.boardView) state.attritionRateBoard.boardView = action.payload
    },

    setAttritionRatePeriod(state, action: PayloadAction<{ startDate: string; endDate: string }>) {
      state.attritionRateBoard.startDate = action.payload.startDate
      state.attritionRateBoard.endDate = action.payload.endDate
    }
  }
})

export const hrDashboardActions = hrDashboardSlice.actions

export default hrDashboardSlice.reducer
